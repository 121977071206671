import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { Select, Table } from "antd"

interface CancelTableProps {}

const CancelTable = (props: CancelTableProps) => {
  const {} = props

  const [searchParams] = useSearchParams()
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  const handlePageSizeChange = (size: number) => {
    setPageSize(size)
  }

  /**
   * column
   */
  const columns = [
    { key: "order_number", title: "주문번호", dataIndex: "order_number" },
    { key: "product_number", title: "상품번호", dataIndex: "product_number" },
    { key: "created", title: "주문일자", dataIndex: "created" },
    { key: "status", title: "상태", dataIndex: "status" },
    { key: "product_name", title: "상품명", dataIndex: "product_name" },
    { key: "option_name", title: "옵션명", dataIndex: "option_name" },
    { key: "count", title: "수량", dataIndex: "count" },
    { key: "price", title: "결제 금액", dataIndex: "price" },
    { key: "payment_method", title: "결제 방법", dataIndex: "payment_method" },
    { key: "orderer_name", title: "주문자", dataIndex: "orderer_name" },
    { key: "receiver_name", title: "수령인", dataIndex: "receiver_name" },
  ]

  const dataSource: any = []
  return (
    <div>
      <div className="flex items-center justify-between">
        <h4 className="mb-6 text-20 font-bold text-gray-900">목록</h4>

        <div className="flex items-center gap-2">
          <Select
            className="w-[127px]"
            options={[
              { label: "10개씩 보기", value: 10 },
              { label: "50개씩 보기", value: 50 },
              { label: "100개씩 보기", value: 100 },
              { label: "200개씩 보기", value: 200 },
              { label: "500개씩 보기", value: 500 },
            ]}
            value={pageSize}
            onChange={handlePageSizeChange}
          />
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={dataSource}
        rowSelection={{
          selectedRowKeys,
          onChange: selectedRowKeys => setSelectedRowKeys(selectedRowKeys),
        }}
      />
    </div>
  )
}

export default CancelTable
