import { Link, useLocation } from "react-router-dom"
import { useMediaQuery } from "@runners/hooks"
import { useQueryClient } from "@tanstack/react-query"
import { UserQueryKeys } from "@/apis/userApi"
import Logo from "@/components/logo"

import useMyInfo from "@/hooks/use-my-info"
import cn from "@/utils/cn"
import { removeAccessToken, removeRefreshToken } from "@/utils/cookie"
import MobileSideMenu, { MENU_TAB } from "./mobile-side-menu"

const Header = () => {
  const queryClient = useQueryClient()
  const user = useMyInfo()
  const { pathname } = useLocation()

  const handleClickLogout = () => {
    removeAccessToken()
    removeRefreshToken()
    queryClient.resetQueries({ queryKey: UserQueryKeys.userMe() })
    queryClient.clear()
  }

  const isMobile = useMediaQuery("(max-width: 767px)")

  return (
    <>
      <header className="sticky top-0 z-10 flex h-[60px] w-full items-center border-b border-b-[#E0E0E0] bg-white px-5 md:h-20">
        <div className="mx-auto flex w-full max-w-[1080px] items-center justify-between">
          <div className="flex items-center gap-6">
            <Link to="/">
              <Logo />
            </Link>

            <div className="flex gap-3 mobile:hidden">
              {MENU_TAB.map(item => {
                const isActive = item.link === pathname
                return (
                  <Link key={item.name} to={item.link} data-tracking-name={`헤더_${item.name}`}>
                    <span
                      className={cn(
                        "flex shrink-0 cursor-pointer items-center justify-center whitespace-nowrap p-3 text-18 transition-all duration-300 ease-in-out",
                        {
                          "text-primary": pathname !== "/" && isActive,
                          "text-gray-700": pathname !== "/" && !isActive,
                        },
                      )}
                    >
                      {item.name}
                    </span>
                  </Link>
                )
              })}
            </div>
          </div>

          <div className="hidden md:block">
            <div className="flex items-center gap-3">
              {!user && (
                <Link to="/sign-in" className="px-4 text-[#626270]" data-tracking-name="헤더_로그인">
                  로그인
                </Link>
              )}

              {user && (
                <button onClick={handleClickLogout} className="px-4 text-[#626270]" data-tracking-name="헤더_로그아웃">
                  로그아웃
                </button>
              )}

              <Link
                to={user ? "/dashboard" : "/sign-in"}
                className="inline-flex h-10 w-[160px] items-center justify-center rounded bg-primary px-6 py-2.5 hover:bg-primary-800"
                data-tracking-name={user ? "헤더_스토어_관리" : "헤더_입점하기"}
              >
                <span className="text-14 font-bold text-white">{user ? "스토어 관리" : "지금 입점하기"}</span>
              </Link>
            </div>
          </div>

          {isMobile && <MobileSideMenu />}
        </div>
      </header>
    </>
  )
}

export default Header
