import { useState } from "react"
import dayjs, { Dayjs } from "dayjs"
import { CurrencyKrwIcon } from "@/assets/icons"
import DashboardHeader from "../components/dashboard-header"
import SettlementDatePicker from "./components/settlement-date-picker"
import SettlementFilterCheckbox from "./components/settlement-filter-checkbox"
import SettlementGuide from "./components/settlement-guide"
import SettlementTable from "./components/settlement-table"
import { DateRangeType, TableFilterContextProvider } from "./context/table-filter-context"

const BREADCRUMB_ITEMS = [
  {
    title: (
      <div className="flex items-center gap-1">
        <CurrencyKrwIcon className="size-3" />
        <span>정산관리</span>
      </div>
    ),
  },
  { title: "정산내역" },
]

const SettlementListPage = () => {
  const today = dayjs()
  const [dates, setDates] = useState<[Dayjs | null, Dayjs | null] | null>([
    today.subtract(3, "months").add(1, "day"),
    today,
  ])

  const [rangeType, setRangeType] = useState<DateRangeType>("threeMonth")

  const [checkboxState, setCheckboxState] = useState({
    all: false,
    waiting: false,
    failed: false,
    complete: true,
  })

  return (
    <div>
      <DashboardHeader title="정산내역" breadcrumbItems={BREADCRUMB_ITEMS} />
      <div className="p-6 pb-20">
        <div className="flex flex-col gap-6">
          <SettlementGuide />

          <TableFilterContextProvider
            checkboxState={checkboxState}
            dates={dates}
            rangeType={rangeType}
            setCheckboxState={setCheckboxState}
            setDates={setDates}
            setRangeType={setRangeType}
          >
            <div className="flex flex-col gap-3 text-14 text-gray-800">
              <SettlementDatePicker />
              <div className="h-px w-full bg-gray-300" />
              <SettlementFilterCheckbox />
              <div className="h-px w-full bg-gray-300" />

              <SettlementTable />
            </div>
          </TableFilterContextProvider>
        </div>
      </div>
    </div>
  )
}

export default SettlementListPage
